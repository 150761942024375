import { Typography } from '@northstar/core';
import { useNavigate } from 'react-router-dom';
import type { ProductPermission } from 'types/permissions';
import type { CaseData, CaseDataResponse } from 'types/cases';
import { useAccounts } from 'contexts/Accounts';
import { useCallback, useMemo, useState } from 'react';
import { DataTable, useTable } from '@northstar/plus';
import { ColumnDef, Row } from '@tanstack/table-core';

import { debounce } from 'utils/debounce';
import { useApp } from 'contexts/App';
import { tableDefaultState } from 'constants/tableDefaultState';
import { usePersistentTableState } from 'hooks/usePersistentTableState';
import { useEffectOnce } from 'hooks';
import { useAuth } from 'contexts/Auth';
import { getToolbarActions } from './getToolbarActions';
import { getFilteredByNames } from './getFilteredByNames';
import { exportCasesToCSV } from './exportCasesToCSV';
import { overrideTableStyles } from './overrideTableStyles';
import { getHeaderColumns } from './columns/getHeaderColumns';
import { TableGlobalStyles } from './TableGLobalStyles';
import { useFilterOptions } from './useFilterOptions';
import { casesDefaultFilterFn } from './filterFn/casesDefaultFilterFn';
import { emptyDataComponent } from './emptyDataComponent';

interface Props {
    productsList: ProductPermission[] | undefined;
    mutateCases: () => Promise<CaseDataResponse>;
    mutateDraftCases: () => Promise<CaseDataResponse>;
    caseData: CaseData[];
    isLoading: boolean;
    isFetching: boolean;
}

const casesTableStorageKey = 'casesTableState';

const tableInitialState = {
    columnVisibility: {
        fortraAssignee: false,
    },
};

const CasesList = ({
    productsList,
    caseData,
    mutateCases,
    mutateDraftCases,
    isLoading = false,
    isFetching = false,
}: Props) => {
    const [tableKey, setTableKey] = useState<number>(1);
    const { user } = useAuth();

    const navigate = useNavigate();
    const { addNotification } = useApp();

    const {
        isSingleAccount,
        caseAccessAccountLevel,
        userAccountsDetails,
        setSelectedAccounts,
        selectedAccounts,
    } = useAccounts();

    const headerColumns: ColumnDef<CaseData>[] = useMemo(
        () =>
            getHeaderColumns({
                caseAccessAccountLevel,
                isSingleAccount,
            }),
        [caseAccessAccountLevel, isSingleAccount]
    );

    const handleAccountSelection = (value: string[]) => {
        if (isSingleAccount) return;

        const oldSelection = selectedAccounts.toString();
        const newSelection = value.toString();

        if (oldSelection === newSelection) return;

        sessionStorage.setItem('selectedAccounts', newSelection);

        setSelectedAccounts(value);
        mutateCases();
        mutateDraftCases();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleAccountSelectionChange = useCallback(
        debounce(handleAccountSelection, 1500),
        [selectedAccounts, isSingleAccount]
    );

    const { tableState, setTableState } = usePersistentTableState(
        {
            ...tableDefaultState,
            ...tableInitialState,
        },
        user?.accountId || casesTableStorageKey
    );

    const tableInstance = useTable<CaseData>({
        autoResetPageIndex: true,
        columns: headerColumns,
        data: caseData || [],
        enableColumnFilters: true,
        enableGlobalFilter: true,
        enablePagination: true,
        enableRowSelection: false,
        enableSorting: true,
        keyField: 'id',
        getColumnCanGlobalFilter: () => true,
        globalFilterFn: casesDefaultFilterFn,
    });

    tableInstance.setOptions((prev) => ({
        ...prev,
        state: tableState,
        onStateChange: setTableState,
    }));

    useEffectOnce(() => {
        tableInstance.setPageIndex(0);
    });

    const { filterOptions } = useFilterOptions({
        tableInstance,
        handleAccountSelectionChange,
        tableKey,
    });

    const handleCaseSelect = (row: Row<CaseData>) => {
        const {
            id,
            ownerInfo: { accountId },
            caseStatus,
        } = row.original;

        sessionStorage.setItem('selectedAccountId', accountId);

        const url =
            caseStatus === 'Draft'
                ? `/support/requests/open#${id}`
                : `/support/requests/${id}`;

        navigate(url);
    };

    const processCSVDownload = () => {
        if (isLoading) return;

        const filteredByNames = getFilteredByNames({
            tableInstance,
            productsList,
            userAccountsDetails,
            globalFilter: tableState.globalFilter,
        });

        const message = exportCasesToCSV(tableInstance, filteredByNames);

        if (message) {
            addNotification({
                message,
                status: 101,
            });
        }
    };

    const resetTable = () => {
        tableInstance.reset();

        setTableState({
            ...tableDefaultState,
            ...tableInitialState,
        });

        setTableKey((key) => key + 1);
    };

    return (
        <>
            <TableGlobalStyles />
            <Typography sx={{ mb: 2 }} variant="h4" component="p">
                Manage Support Requests
            </Typography>
            <DataTable
                sx={overrideTableStyles}
                emptyDataComponent={emptyDataComponent}
                toolbarActions={getToolbarActions({
                    processCSVDownload,
                    isLoading,
                    resetTable,
                })}
                key={tableKey}
                displayGutter={false}
                filterOptions={filterOptions}
                isLoading={isLoading}
                isFetching={isFetching}
                onRowClick={handleCaseSelect}
                useVirtualRows
                tableInstance={tableInstance}
                scrollBehavior="bounded"
                paginationPageSizes={[10, 25, 50, 100]}
            />
        </>
    );
};

export default CasesList;
