type ConvertJSONToCSV<T> = {
    jsonData: T[];
    columnHeaders: string[];
    additionalLine?: string;
};

type DownloadCSV<T> = {
    jsonData: T[];
    headers: string[];
    fileName?: string;
    additionalLine?: string;
};

const convertJSONToCSV = <T extends Record<string, any>>({
    jsonData,
    columnHeaders,
    additionalLine = '',
}: ConvertJSONToCSV<T>) => {
    if (!jsonData.length) return '';

    const headers = `${columnHeaders.join(',')}\n`;

    const rows = jsonData
        .map((row) =>
            columnHeaders
                .map((header) => (header in row ? row[header] : ''))
                .join(',')
        )
        .join('\n');

    return headers + rows + (additionalLine ? `\n${additionalLine}` : '');
};

const downloadCSV = <T extends Record<string, any>>({
    jsonData,
    headers,
    fileName = 'data.csv',
    additionalLine = '',
}: DownloadCSV<T>) => {
    const csvData = convertJSONToCSV({
        jsonData,
        columnHeaders: headers,
        additionalLine,
    });

    if (!csvData) return;

    const BOM = '\uFEFF';

    const csvContent = BOM + csvData;

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');

    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
};

export { downloadCSV };
