import { SelectFilter, useTableFilterState } from '@northstar/plus';
import { Table } from '@tanstack/table-core';
import { useAccounts } from 'contexts/Accounts';
import { useEffectOnce, usePrevious } from 'hooks';
import { caseStatusList } from 'pages/Support/components/CasesList/caseStatusList';
import { useCallback, useEffect, useMemo } from 'react';
import { CaseData } from 'types/cases';

type Props = {
    tableInstance: Table<CaseData>;
    handleAccountSelectionChange: any;
    tableKey: number;
};

const useFilterOptions = ({
    tableInstance,
    handleAccountSelectionChange,
    tableKey,
}: Props) => {
    const {
        userAccountsDetails,
        selectedAccounts,
        isSingleAccount,
        mergedPermissionProducts,
    } = useAccounts();

    const userIds = userAccountsDetails.map((item) => item.accountId);

    const prevTableKey = usePrevious(tableKey);

    const [orgFilter, setOrgFilter] = useTableFilterState(
        tableInstance,
        'ownerInfo',
        selectedAccounts
    );

    const [productFilter, setProductFilter] = useTableFilterState(
        tableInstance,
        'product'
    );

    const [statusFilter, setStatusFilter] = useTableFilterState(
        tableInstance,
        'caseStatus'
    );

    const handleOrgSelection = useCallback(
        (data: string[]) => {
            const newState =
                data.length === 0
                    ? orgFilter
                    : data.sort(
                          (a: string, b: string) =>
                              userIds.indexOf(a) - userIds.indexOf(b)
                      );

            setOrgFilter(newState);
            handleAccountSelectionChange(newState);
        },
        [handleAccountSelectionChange, orgFilter, setOrgFilter, userIds]
    );

    const handleOrgSelectionClear = useCallback(() => {
        setOrgFilter(userIds);
        handleAccountSelectionChange(userIds);
    }, [handleAccountSelectionChange, setOrgFilter, userIds]);

    useEffectOnce(() => {
        setOrgFilter(selectedAccounts);
    });

    useEffect(() => {
        if (tableKey && prevTableKey && tableKey !== prevTableKey) {
            handleOrgSelectionClear();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableKey, prevTableKey]);

    const filterOptions = useMemo(() => {
        const filters: any = [];

        if (!isSingleAccount) {
            const OrgFilter = {
                name: 'ownerInfo.account',
                id: 'ownerInfo',
                component: () => (
                    <SelectFilter
                        id="OrganizationSelect"
                        filterName="Organization"
                        value={orgFilter}
                        setValue={handleOrgSelection}
                        options={userAccountsDetails.map((item) => ({
                            value: item.accountId,
                            label: item.accountName,
                        }))}
                        multiple
                        defaultValue={undefined}
                    />
                ),
                alwaysShow: true,
                clear: handleOrgSelectionClear,
                canBeCleared: () => orgFilter?.length !== userIds.length,
            };

            filters.push(OrgFilter);
        }

        const ProductFilter = {
            name: 'Product',
            id: 'product',
            component: () => (
                <SelectFilter
                    id="product"
                    filterName="Product"
                    value={productFilter}
                    setValue={setProductFilter}
                    options={(mergedPermissionProducts || []).map(
                        (item) => item.productName
                    )}
                    multiple
                    defaultValue={undefined}
                />
            ),
            alwaysShow: true,
            clear: () => setProductFilter(undefined),
            canBeCleared: () => (productFilter || []).length > 0,
        };

        const StatusFilter = {
            name: 'Status',
            id: 'caseStatus',
            component: () => (
                <SelectFilter
                    id="caseStatus"
                    filterName="Status"
                    value={statusFilter}
                    setValue={setStatusFilter}
                    options={caseStatusList}
                    multiple
                    defaultValue={undefined}
                />
            ),
            alwaysShow: true,
            clear: () => setStatusFilter(undefined),
            canBeCleared: () => (statusFilter || []).length > 0,
        };

        filters.push(ProductFilter);
        filters.push(StatusFilter);

        return {
            filters,
        };
    }, [
        handleOrgSelection,
        handleOrgSelectionClear,
        isSingleAccount,
        mergedPermissionProducts,
        orgFilter,
        productFilter,
        setProductFilter,
        setStatusFilter,
        statusFilter,
        userAccountsDetails,
        userIds.length,
    ]);

    return { filterOptions };
};

export { useFilterOptions };
