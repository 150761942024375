import { TableState } from '@tanstack/table-core';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'utils/debounce';
import { parseJsonString } from 'utils/parseJsonString';

const usePersistentTableState = (
    defaultState: TableState,
    storageKey: string
) => {
    const [tableState, setTableState] = useState<TableState>(() => {
        const savedState = parseJsonString(
            localStorage.getItem(storageKey) || ''
        );

        return {
            ...defaultState,
            ...(savedState || {}),
        };
    });

    const debouncedSave = useRef(
        debounce((newState: TableState) => {
            localStorage.setItem(storageKey, JSON.stringify(newState));
        }, 800)
    );

    useEffect(() => {
        debouncedSave
            .current(tableState)
            .catch((error) =>
                console.error('Failed to save table state:', error)
            );
    }, [tableState, storageKey]);

    return { tableState, setTableState };
};

export { usePersistentTableState };
