import DownloadIcon from '@mui/icons-material/Download';
import { Action } from '@northstar/plus';
import { CaseData } from 'types/cases';
import { SettingsBackupRestore } from '@northstar/icons';
import { Box, IconButton } from '@northstar/core';

type Props = {
    processCSVDownload: () => void;
    resetTable: () => void;
    isLoading: boolean;
};

const getToolbarActions = ({
    processCSVDownload,
    resetTable,
    isLoading,
}: Props): readonly Action<CaseData>[] => {
    const actions: Action<CaseData>[] = [
        {
            label: 'Reset',
            onClick: isLoading ? () => null : resetTable,
            icon: (
                <IconButton
                    component={Box}
                    sx={{ padding: 0 }}
                    disabled={isLoading}
                    disableFocusRipple
                >
                    <SettingsBackupRestore />
                </IconButton>
            ),
            type: 'icon',
        },
        {
            label: 'Export',
            onClick: isLoading ? () => null : processCSVDownload,
            icon: (
                <IconButton
                    component={Box}
                    sx={{ padding: 0 }}
                    disabled={isLoading}
                    disableFocusRipple
                >
                    <DownloadIcon />
                </IconButton>
            ),
            type: 'icon',
        },
    ];

    return actions;
};

export { getToolbarActions };
